import React from 'react';

import Character from './Character';

class Board extends React.Component {
  render() {
    return (
      <ul className="characters">
        {this.props.characters.map((char, index) => {
          return (
            <li key={char.name}>
              <Character
                index={index}
                name={char.name}
                imgSrc={char.imgSrc}
                discarded={char.discarded}
                toggleCharacter={this.props.toggleCharacter}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}

export default Board;

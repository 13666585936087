import React from 'react';

import Emoji from './Emoji';

class NotFound extends React.Component {
  goToMainSite = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div className="site">
        <p className="header">
          looks like you're lost... <Emoji emoji="scream" />
        </p>
        <p className="subheader">
          <a href="/" onClick={this.goToMainSite}>
            head over to the main page
          </a>
        </p>
      </div>
    );
  }
}

export default NotFound;

import React from 'react';

class Emoji extends React.Component {
  emojis = {
    wave: '👋',
    rocket: '🚀',
    scream: '😱',
    evil: '🙈',
  };

  title = title => {
    return ':' + this.props.emoji + ':';
  };

  render() {
    return (
      <span className="emoji" title={this.title()}>
        {this.emojis[this.props.emoji]}
      </span>
    );
  }
}

export default Emoji;

import React from 'react';

class Character extends React.Component {
  render() {
    let classes = ['character'];
    if (this.props.discarded) {
      classes.push('discarded');
    }

    return (
      <img
        className={classes.join(' ')}
        alt={this.props.name}
        src={this.props.imgSrc}
        onClick={() => this.props.toggleCharacter(this.props.index)}
      />
    );
  }
}

export default Character;

import React from 'react';

import Emoji from './Emoji';

class Site extends React.Component {
  href = {
    telegram: 'https://t.me/thisiserico',
    twitter: 'https://twitter.com/thisiserico',
    instagram: 'https://instagram.com/thisiserico',
    github: 'https:///github.com/thisiserico',
    linkedin: 'https://www.linkedin.com/in/thisiserico',
  };

  render() {
    return (
      <div className="site">
        <p className="header">
          <Emoji emoji="wave" />
          <Emoji emoji="wave" /> this is eric<span className="erico">o</span>
        </p>
        <p className="subheader">
          a software engineer from Barcelona that happens to love his craft
          (among many other things) <Emoji emoji="rocket" />
        </p>

        <p className="connect">
          <a href={this.href.telegram} target="_blank" rel="noopener noreferrer">
            text me
          </a>{' '}
          or reach me somewhere else!<br />
          <a href={this.href.instagram} target="_blank" rel="noopener noreferrer" className="icon">
            instagram
          </a>{' '}
          <a href={this.href.github} target="_blank" rel="noopener noreferrer" className="icon">
            github
          </a>{' '}
          <a href={this.href.linkedin} target="_blank" rel="noopener noreferrer" className="icon">
            linkedin
          </a>{' '}
          <a href={this.href.twitter} target="_blank" rel="noopener noreferrer" className="icon">
            twitter
          </a>
        </p>
      </div>
    );
  }
}

export default Site;

import React from 'react';

import Board from './wiw/Board';
import Emoji from './Emoji';

class Wiw extends React.Component {
  state = {
    selectedCharacter: [],
    allCharacters: [],
  };

  componentWillMount() {
    let originalNames = this.allPossibleNames();
    let parsedNames = this.randomizeArray(originalNames);

    this.setState({
      selectedCharacter: this.pickUpCharacterIndex(originalNames.length),
      allCharacters: this.prepareCharacters(parsedNames),
    });
  }

  allPossibleNames = () => {
    return [
      'brett',
      'buddy',
      'butch',
      'captain-koons',
      'esmeralda',
      'fabienne',
      'jimmie',
      'jody',
      'jules',
      'lance',
      'marcellus',
      'marvin',
      'maynard',
      'mia',
      'paul',
      'raqel',
      'ringo',
      'roger',
      'the-gimp',
      'trudi',
      'vincent',
      'winston',
      'yolanda',
      'zed',
    ];
  };

  // credits to https://stackoverflow.com/a/2450976/1402745
  randomizeArray = originalArray => {
    let array = originalArray.slice(0);

    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  pickUpCharacterIndex = length => {
    return new Date().getSeconds() % length;
  };

  prepareCharacters = names => {
    let chars = new Array(names.length);

    names.forEach((rawName, index) => {
      let name = rawName
        .split('-')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ');

      chars[index] = {
        name: name,
        imgSrc: '/images/wiw/' + rawName + '.png',
        discarded: false,
      };
    });

    return chars;
  };

  toggleCharacter = index => {
    const characters = [...this.state.allCharacters];
    characters[index].discarded = !characters[index].discarded;

    this.setState({allCharacters: characters});
  };

  render() {
    return (
      <div className="site">
        <p className="header">
          <Emoji emoji="evil" /> who is who?
        </p>

        <Board
          characters={this.state.allCharacters}
          toggleCharacter={this.toggleCharacter}
        />
      </div>
    );
  }
}

export default Wiw;

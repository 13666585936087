import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Site from './Site';
import Wiw from './Wiw';
import NotFound from './NotFound';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Site} />
      <Route exact path="/wiw" component={Wiw} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
